/* Colour Pallete */
$white-1: white; // background color

$blue-1: #1a67d2; // background color
$blue-50: #f5f8fa; // background color
$blue-100: #e7f3fb; // background color
$blue-150: #e3ecf9; // background color
$blue-200: #96c2ea; // background color
$blue-300: #1a67d2; // background color
$blue-400: #0078b4; // background color
$blue-800: #5c7999;

$ui-blue: #199cf9; // background color

$grey-100: #f4f6fa;
$grey-200: #e4eaf0;
$grey-300: #a3b5c9; // background color
$grey-800: #4b637d;

//z-index
$z-index-zero: 0;
$z-index-lowest: 1;
$z-index-low: 10;
$z-index-middle: 20;
$z-index-high: 30;
$z-index-highest: 40;

//basic bootstrap colors override
$danger-bg: #FFE8E7;
$danger-border: #DA352A;
$danger-text: #583400;

$warning-bg: #FBF0E0;
$warning-border: #DD8101;
$warning-text: #58340D;

$success-bg: #E2F2E8;
$success-border: #0D8f40;
$success-text: #05391A;

$info-bg: #E3ECF9;
$info-border: #1860CC;
$info-text: #0A2652;

body {
  overflow-y: hidden !important;
}


.white-background {
  background-color: $white-1 !important;
}

#root {
  background-color: $grey-200;
}

.small-padding {
  padding: 1rem;
}

.very-small-padding {
  padding: 0.5rem;
}

.center-text {
  text-align: center;
}

.messenger-load-page {
  height: 40rem;
  margin-bottom: 10rem;
}

.messenger-header {
  background-color: $blue-1;
  color: $white-1;
  padding: 0.5rem;
}

.messenger-header-left {
  background-color: $blue-1;
  color: $white-1;
  padding: 1rem;
}

.messenger-header h1 {
  color: $white-1 !important;
  padding: 0 !important;
  text-decoration: none !important;
  border: none !important;
}

.messenger-header span {
  color: $white-1 !important;
}


.messenger-header svg {
  color: $white-1 !important;
}

/* text alignment */


.right-aligned-text {
  text-align: right;
}


.center-aligned-text {
  text-align: center;
}

.verticaly-centered {
  position: absolute !important;
  top: 50% !important;
}


div::-webkit-scrollbar {
  width: 0.5em;
}

div::-webkit-scrollbar-track {
  background-color: #E5E5E5;
}

div::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.message-user-icon {
  position: absolute;
  bottom: 1rem;
  display: block;
  max-width: 2rem;
}

.user-photo-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  align-items: center;

  .user-photo {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: inherit;
    height: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.full-height {
  height: 100%;
}

#threadCanvasSection {
  overflow-x: hidden;
}

#thread-header {
  margin-top: 0.5rem;
  background-color: $white-1;
  text-align: center;
}

.showDate {
  &_left {
    margin-top: 20px;
    text-align: left;
  }

  &_right {
    margin-top: 20px;
    text-align: right;
  }
}

.empty-thread-canvas {
  height: 50%;
  text-align: center;
  margin-top: 5em;
  margin-bottom: 2rem;
}

.empty-thread-canvas svg {
  width: 50% !important;
  height: 50% !important;;
}


.section-separator {
  color: $grey-300;
  padding: 0.2rem;
}

.messenger-card {
  background-color: $grey-100 !important;
}

.inline {
  display: inline;
}

.section-icon {
  margin-right: 0.5rem;
}

.section-header {
  padding: 1rem;
  text-align: center;
}

.btn {
  font-weight: bold !important;
  border-width: 0.1rem;

  &.btn-outline-primary {
    border-color: $grey-300 !important;
    color: $blue-400 !important;
    background-color: white !important;

    &.active {
      border-color: $blue-400 !important;
      background: $blue-50 !important;
      box-shadow: 0 0.25rem 0.25rem -0.25rem rgb(0 0 0 / 30%);
    }
  }

  &.btn-menu {
    width: 100%;
    justify-content: left !important;
    border-radius: 0 !important;

    border-color: $grey-300 !important;
    color: $blue-400 !important;
    background-color: white !important;

    &:hover {
      border-color: $blue-400 !important;
      background: $blue-50 !important;
      box-shadow: 0 0.25rem 0.25rem -0.25rem rgb(0 0 0 / 30%);
    }
  }
}

.g-spinner.g-spinner-xs {
  --size: 0.8rem !important;
  --stroke-width: 0.1875rem;
}

#like-button {
  background-color: $blue-1 !important;
}

.font-time {
  font-size: 10px;
}

.message {
  .outgoing-message {
    border-bottom-right-radius: 0px !important;
    box-shadow: none !important;
  }

  .incoming-message {
    border-bottom-left-radius: 0px !important;
    margin-top: 2px !important;
  }
}

.pointer {
  cursor: pointer
}

//class rounded-circle from bootstrap is not working as expected (creates ellipsis)
.rounded-icon {
  padding: 0.5rem !important;
  border-radius: 5rem !important;
}

.rounded-icon-lg {
  padding: 1rem !important;
  border-radius: 5rem !important;
}

.textarea-expanding {

  .textarea:not(.disabled){
    cursor: text;
  }

  .textarea {
    width: 100%;
    border-radius: 0.25rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 30rem;
    border: .0625rem solid #a3b5c9;
    outline: none;
    box-shadow: inset 0 5px 6px -4px rgb(0 0 0 / 17%);
    color: #21416c;

    &::-webkit-scrollbar {
      width: 0.8em;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1em;
      background-color: #E5E5E5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1em;
      background-color: #999999;
    }

    &:not(.disabled):empty::before {
      content: "Zde můžete psát...";
      color: #5c7999;
    }

    &.disabled:empty::before {
      content: "Odeslat zprávu není možné...";
      color: #5c7999;
    }

    &:focus {
      outline: none;
      border-color: rgba(26, 103, 210, .7);
    }
  }
}

.badge-file {
  border: 2px solid #a3b5c9;
  color: #1a67d2;
}

.attachment-area {
  background: #e4eaf0;
  z-index: 10;
  position: absolute;
  bottom: 0;
}

.centerSpinner {
  text-align: center !important;
  z-index: 10 !important;
}

.notesBody {
  overflow-y: auto;
  //height: 100%;
}

.message-group {
  margin-top: 1rem;
}

.downloadButtonIn {
  color: #21416C !important;
  background-color: unset !important;
}


.badge-info {
  background-color: $blue-300 !important;
}

#header-alert {
  margin: 0;
  background-color: #d1ebfe;
  border: .0625rem solid #199cf9 !important;

}

#warning-action-stripe {
  background: #fceed2;
  border: .0625rem solid #f2a91e !important;
  font-size: 0.875rem !important;
  border-radius: 0.3rem !important;
  margin-left: 0.05rem;
  margin-right: 0.05rem;
}

#warning-action-stripe svg {
  color: #f2a91e;
}

#info-action-stripe {
  border: .0625rem solid #199cf9 !important;
  background: #d1ebfe;
  font-size: 0.875rem !important;
  border-radius: 0.3rem !important;
  margin-left: 0.05rem;
  margin-right: 0.05rem;
}

#info-action-stripe p {
  font-size: 0.875rem !important;
}

#info-action-stripe svg {
  color: #199cf9;
}

.dropdown-stripe-button {
  width: 30%;
}

.primary-action-stripe-button {
  width: 70%;
}


.floatRightLocal {
  float: right !important;
}

.blocking-layer {
  color: $grey-800;

  svg {
    width: 15rem;
    height: auto;
    fill: $blue-200;
    color: $blue-200;
  }
}

.notehover:hover {
  background-color: $grey-200 !important
}

/*Čistě kvůli noteModal, kde si ty styly dělají co chtěj*/
.noteTextSection {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%
}

/* ALERT FLOATING */
.floating-info-container {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: $z-index-high;
  width: 25%
}

.floating-info-container .alert {
  margin-bottom: 0;
  padding: 0.8rem 1rem;
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
}

.alert {
  &.alert-info {
    background: $info-bg !important;
    border-color: $info-border !important;
    color: $info-text;
  }
}

.alert {
  &.alert-warning {
    background: $warning-bg !important;
    border-color: $warning-border !important;
    color: $warning-text;
  }
}

.alert {
  &.alert-success {
    background: $success-bg !important;
    border-color: $success-border !important;
    color: $success-text;
  }
}

.alert {
  &.alert-danger {
    background: $danger-bg !important;
    border-color: $danger-border !important;
    color: $danger-text;
  }
}

.z-index-lowest {
  z-index: $z-index-lowest;
}

.z-index-low {
  z-index: $z-index-low;
}

.z-index-middle {
  z-index: $z-index-middle;
}

.z-index-high {
  z-index: $z-index-high;
}

.z-index-highest {
  z-index: $z-index-highest;
}

.modal {
  .modal-header {
    display: block;

    h5 {
      font-weight: bold;
    }

    button {
      position: absolute;
      right: 0.5rem;
      top: 0.75rem;
      border: none;
      background: white;
      font-size: 1.5rem;
      color: $grey-300
    }
  }

  .modal-body{
    > .row {
      margin-bottom: 2rem;
    }
  }

  .modal-dialog {
    &.info {
      .modal-content {
        background: $info-bg;
        border: 1px solid $info-border;
        color: $info-text;
        fill: $info-text;
      }
    }

    &.warning {
      .modal-content {
        background: $warning-bg;
        border: 1px solid $warning-border;
        color: $warning-text;
        fill: $warning-text;
      }
    }

    &.success {
      .modal-content {
        background: $success-bg;
        border: 1px solid $success-border;
        color: $success-text;
        fill: $success-text;
      }
    }

    &.danger {
      .modal-content {
        background: $danger-bg;
        border: 1px solid $danger-border;
        color: $danger-text;
        fill: $danger-text;
      }
    }
  }
}

.action-stripe-buttons {
  .collapse{
    border: 1px solid $blue-100;
    box-shadow: 0 0.25rem 0.25rem -0.25rem rgb(0 0 0 / 30%);
  }

  .collapsing{
    border: 1px solid $blue-100;
    box-shadow: 0 0.25rem 0.25rem -0.25rem rgb(0 0 0 / 30%);
  }
}

.form-group{
  label {
    font-weight: bold;
    margin-bottom: 0.5rem;

    .text-danger {
      margin-left: 0.5rem;
      font-weight: normal;
    }
  }

  .input-group-append{
    button {
      height: 100%;
    }
  }
}

.text-section {
  color: $grey-800;
}

.text-muted {
  color: $grey-300 !important;
  fill:  $grey-300 !important;
}

.text-primary {
  color: $blue-300 !important;
  fill:  $blue-300 !important;
}

.text-secondary{
  color: $blue-800 !important;
  fill:  $blue-800 !important;
}

.disabled {
  opacity: 0.7;
}

.history-item{
  background: $grey-100 !important;

  &.odd{
    background: $blue-100 !important;
  }
}

.g-image-24{
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.g-bootstrap.g-store p {
  font-weight: 200 !important;
}

.app-link{
  font-weight: 500 !important;
  font-style: normal;
}

.messageHref {
  a {
    color: $white-1 !important;
    text-decoration: underline !important;
  }
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}

.quill p {
  font-family: Inter, sans-serif !important;
  font: #21416c;
  padding: 0 !important;
}

.quill .ql-container {
  border: 1px solid #dee2e6 !important
}

.quill .ql-toolbar {
  border: 1px solid #dee2e6 !important
}

.quill .ql-editor {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 1rem;
  font-style: normal;
  color: #A9A9A9;
  padding-top: 0.125rem;
  font-family: Inter, sans-serif;
  content: "Zde můžete psát...";
}

.quill .ql-bubble .ql-editor {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

// Overriding the react-quill CSS to move the hypertext tooltip above the input.
#snow-toolbar .ql-editing {
  top: -2rem !important;
}

.right-menu {
  .backdrop {
    background-color: rgba(10,40,92,.3)
  }
}

.arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 10px 5px 0;
  border-color: transparent white transparent transparent;
}

.arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent $blue-300;
}

.g-focus-page-content{
  padding: 0!important;
}

.right-menu > div {
  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
  -ms-animation: fadein 0.2s; /* Internet Explorer */
  -o-animation: fadein 0.2s; /* Opera < 12.1 */
  animation: fadein 0.2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


