body {
  margin: 0;
}

* {
  font-family: 'GeorgeFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 BOLD 700 - 900
 normal
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 700;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Bold.5a100916.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 800;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-ExtraBold.19b56cfc.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 900;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-ExtraBold.19b56cfc.ttf) format('truetype');
}

/**
  BOLD 700 - 900
  italic
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 700;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-BoldItalic.c36b5ac7.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 800;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-ExtraBoldItalic.4595d7f8.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 900;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-ExtraBoldItalic.4595d7f8.ttf) format('truetype');
}

/**
  SEMIBOLD 500 - 600
  italic
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 500;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-SemiboldItalic.1c0b4eb9.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 600;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-SemiboldItalic.1c0b4eb9.ttf) format('truetype');
}

/**
  SEMIBOLD 500 - 600
  normal
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 500;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Semibold.33f225b8.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 600;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Semibold.33f225b8.ttf) format('truetype');
}

/**
  REGULAR 300-400
  italic
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 300;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Italic.9b30f134.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 400;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Italic.9b30f134.ttf) format('truetype');
}

/**
  REGULAR 300-400
  normal
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 300;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Regular.cd729635.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 400;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Regular.cd729635.ttf) format('truetype');
}

/**
  LIGHT 100-200
  italic
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 100;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-LightItalic.26f1e68d.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: italic;
  font-weight: 200;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-LightItalic.26f1e68d.ttf) format('truetype');
}

/**
  LIGHT 100-200
  normal
 */
@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 100;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Light.ecb4572a.ttf) format('truetype');
}

@font-face {
  font-family: 'GeorgeFont';
  font-style: normal;
  font-weight: 200;
  src: local('GeorgeFont'), url(./assets/fonts/OpenSans-Light.ecb4572a.ttf) format('truetype');
}
